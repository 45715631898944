import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import { ScrollToTop } from 'components';
import {PathRouteProps} from 'react-router';

const Home = loadable(() => import('./Home'));
const Disclaimer = loadable(() => import('./Disclaimer'));
const Gallery = loadable(() => import('./Gallery'));
const Reviews = loadable(() => import('./Reviews'));
const Episodes = loadable(() => import('./Episodes'));
const Episode = loadable(() => import('./Episode'));
const Ringtones = loadable(() => import('./Ringtones'));
const Contact = loadable(() => import('./Contact'));
const About = loadable(() => import('./About'));
const EightiesPhotos = loadable(() => import('./EightiesPhotos'));
const ArcadeQuiz = loadable(() => import('./ArcadeQuiz'));
const Store = loadable(() => import('./Store'));

export interface PrivateRouteProps extends PathRouteProps {
  redirect?: string;
}

export const ROUTES = {
  home: '/',
  disclaimer: '/disclaimer',
  gallery: '/gallery',
  gallery_80s: '/gallery/80s',
  episodes: '/episodes',
  ringtones: '/ringtones',
  contact: '/contact',
  about: '/about',
  episode: (id = ':id') => `/episode/${id}`,
  arcade: (id = ':id') => `/quiz/arcade/${id}`,
  arcade_blank: '/quiz/arcade',
  cigars: '/cigars',
  store: '/store',
  shop: '/shop',
};

export const InternalRoutes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route index path={ROUTES.home} element={<Home/>} />
          <Route path={ROUTES.disclaimer} element={<Disclaimer/>} />
          <Route path={ROUTES.gallery} element={<Gallery/>} />
          <Route path={ROUTES.episodes} element={<Episodes/>} />
          <Route path={ROUTES.episode()} element={<Episode/>} />
          <Route path={ROUTES.ringtones} element={<Ringtones/>} />
          <Route path={ROUTES.contact} element={<Contact/>} />
          <Route path={ROUTES.about} element={<About/>} />
          <Route path={ROUTES.gallery_80s} element={<EightiesPhotos/>} />
          <Route path={ROUTES.arcade_blank} element={<ArcadeQuiz/>} />
          <Route path={ROUTES.arcade()} element={<ArcadeQuiz/>} />
          <Route path={ROUTES.cigars} element={<Store/>} />
          <Route path={ROUTES.shop} element={<Store/>} />
          <Route path={ROUTES.store} element={<Store/>} />
        </Routes>
        <Reviews/>
      </ScrollToTop>
    </Router>
  )
          
}