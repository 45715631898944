import { ReactNode } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Link, { LinkProps } from '@mui/material/Link';
import styled from '@emotion/styled';

export const InternalLink = ({ to, children, color, style = { textDecoration: 'underline', cursor: 'pointer' }, ...rest }: RouterLinkProps) => (
    <Link 
        component={StyledRouterLink}
        to={to}
        color={color}
        style={{ ...style }}
        {...rest} 
    >
        {children}
    </Link>
)

interface ExternalLinkProps extends LinkProps {
    to: string;
    onClick?: () => void;
    asLink?: boolean;
    children?: ReactNode;
    color?: string;
  }
  
export const ExternalLink = ({ 
    to, 
    onClick = () => true, 
    asLink = true, 
    color = 'inherit',
    children, 
    ...rest
}: ExternalLinkProps) => (
      <Link component="a" href={to || '#'} sx={{ cursor: asLink ? 'pointer' : 'inherit', color }} target="_blank" onClick={onClick} { ...rest }>
        {!!children ? children : to}
      </Link>
);
  

const StyledRouterLink = styled(RouterLink)`
    cursor: pointer;
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
`