import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import XMLParser from 'react-xml-parser';
import { cdnImage } from 'components';
import {Parser} from 'html-to-react';
import type { Episode, EpisodeProduct, EpisodeMeta } from 'types';
import { episodeMeta } from 'data';

const parser = Parser();
 
const DEFAULT_PROFILE_IMAGE = 'nerdherfers-profile-v2.jpg';

const getUrlId = (link: string) => new URL(link).pathname.replace(/\/$/, '').split('/').pop() || '';

export const useFeed: () => Episode[] = () => {

    const [feed, setFeed] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            const result: Episode[] = [];
            await fetch('https://feed.podbean.com/nerdherfers/feed.xml')
                .then(res => res.text())
                .then(text => new XMLParser().parseFromString(text))
                .then(async json => {
                    const items = await json.getElementsByTagName('item');
                    for (const item of items) {
                        const guid = await item.getElementsByTagName('guid')?.[0]?.value?.split('/').pop();
                        const title = await item.getElementsByTagName('title')?.[0].value;
                        let image = (await item.getElementsByTagName('itunes:image')?.[0]?.attributes?.href);
                        if (!image) image = cdnImage(DEFAULT_PROFILE_IMAGE);
                        const link = await item.getElementsByTagName('link')?.[0].value;
                        const text = parser.parse((await item.getElementsByTagName('itunes:summary')?.[0])?.value.replace(/\n/gi, '<br/>').replace(/>$/, ''));
                        const d = await item.getElementsByTagName('itunes:duration')?.[0].value;
                        const season = parseInt(await item.getElementsByTagName('itunes:season')?.[0]?.value) || 0;
                        const episode = parseInt(await item.getElementsByTagName('itunes:episode')?.[0]?.value) || 0;
                        const trackedLink = await item.getElementsByTagName('enclosure')?.[0]?.attributes?.url;
                        let duration = 0;
                        if (d) {
                            let [hr, min, sec] = d.split(':');
                            if (!sec) {
                                sec = min;
                                min = hr;
                                hr = 0;
                            }
                            duration = (parseInt(hr) * 60) + parseInt(min);
                        }
                        const dt = await item.getElementsByTagName('pubDate')?.[0].value;
                        const published = dt ? DateTime.fromJSDate(new Date(dt)) : null;
                        const id = getUrlId(link);
                        let meta = episodeMeta.find((m: EpisodeMeta) => m.season === season && m.episode === episode);
                        result.push({
                            id,
                            guid,
                            title,
                            image,
                            link,
                            text,
                            published,
                            duration,
                            season,
                            episode,
                            trackedLink,
                            meta,
                        });
                    }
                })
                .catch(err => { 
                    console.log(err.message);
                    return {};
                });
            setFeed(result);
        })();
    },[])

    return feed;

}