import React, {useMemo, useReducer, useEffect, PropsWithChildren} from 'react';
import {reducer} from 'lib';
import { EpisodeProduct } from 'types';
import { useCollection } from 'react-firebase-hooks/firestore';
import { query, where, collection } from 'firebase/firestore';
import { useFirebaseContext } from 'hooks';
import { DateTime } from 'luxon';

export interface IStoreContext {
  products: EpisodeProduct[],
  dedupedProducts: EpisodeProduct[],
}

export const StoreContext =
  React.createContext<Partial<IStoreContext>>({});

export const StoreProvider = ({children}: PropsWithChildren) => {

    const { db, defaultListenerOptions } = useFirebaseContext();

    const q = process.env.REACT_APP_STAGE !== 'production' 
        ?   query(
                collection(db!, 'products'),
            )
        :   query(
                collection(db!, 'products'),
                where('availableAt', '<=', DateTime.utc().toJSDate())
            )

    const [documents] = useCollection(
        query(q),
        defaultListenerOptions,
    )

    const [{
        products = [],
    }, dispatch] = useReducer(reducer, {});

    const dedupedProducts = useMemo(() => 
        products.filter((p1: EpisodeProduct, index: number) => products.findIndex((p2: EpisodeProduct) => p2.name === p1.name) === index)
    ,[products]);

    useEffect(() => {
        if (!documents?.docs?.length) return;
        dispatch({ 
            products: documents.docs.map(doc => doc.data() as EpisodeProduct)
        })
    },[documents]);

    return (
        <StoreContext.Provider value={{ products, dedupedProducts }}>
            {children}
        </StoreContext.Provider>
    );
};
