

import { useFirebaseContext } from 'hooks';
import { DocumentReference, collection, doc } from 'firebase/firestore';

export interface Refs {
  quizRef: (id: string) => DocumentReference;
}

export const useRefs: () => any = () => {

    const { db } = useFirebaseContext();

    if (!db) return {};

    return {
        quizRef: (id: string) => doc(collection(db, 'quiz'), id),
    }
  
};
